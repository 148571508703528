import React, { forwardRef, useEffect, useRef, useState } from "react";
import ContentEditable from "../ContentEditable";
import "./style.scss";
import {
  addImageIcon,
  boldIcon,
  dragIcon,
  italicIcon,
  removeSectionIcon,
  resetColorIcon,
  underlineIcon,
  whitePlusIcon,
} from "assets";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import FlipMove from "react-flip-move";
import _, { template } from "lodash";
import DeleteDialog from "dialogs/DeleteDialog";
import ImagePreview from "component/ImagePreview";
import { getColorOrShadowForTemplate } from "utils/constant";

const TYPE = "SLIDE";

const DraggableSlideSection = forwardRef(({ ...props }, ref) => {
  const refSlide = useRef(null);
  const isContentEditableFocused = useRef(false);

  const [, drag] = useDrag({
    type: TYPE,
    item: { id: props.id },
    canDrag: () => !isContentEditableFocused.current,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      props.setDraggingItem(null);
    },
  });

  const [, drop] = useDrop({
    accept: TYPE,
    hover: (item, monitor) => {
      if (!refSlide.current) {
        return;
      }

      const dragIndex = item.id;
      const hoverIndex = props.id;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = refSlide.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (
        (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
        (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
      ) {
        return;
      }

      props.moveSection(dragIndex, hoverIndex);
      item.id = hoverIndex;
    },
  });

  drag(drop(refSlide));

  return (
    <div ref={ref}>
      <SlideSection
        ref={refSlide}
        isDragging={props.draggingItem === props.section}
        setContentEditableFocused={isContentEditableFocused}
        {...props}
      />
    </div>
  );
});

const SlideSection = React.forwardRef(
  (
    {
      section,
      onContentChange,
      activeSection,
      isPresenting,
      index,
      deleteSection,
      onAddImageClick,
      isDragging,
      noDrag = false,
      noRemove = false,
      noSubtitleEdit = false,
      setContentEditableFocused,
      slideId
    },
    ref
  ) => {
    const [isTitleFocused, setIsTitleFocused] = React.useState(false);
    const [isContentFocused, setIsContentFocused] = React.useState(false);

    const handleFocus = () => {
      setContentEditableFocused.current = true;
    };

    const handleBlur = () => {
      setContentEditableFocused.current = false;
    };

    const deleteSectionIconUI = (
      <DeleteDialog
        className="delete-confirmation"
        deleteback={() => {
          deleteSection(index);
        }}
        text={"Are you sure you want to delete selected section?"}
        subText={null}
      >
        <img src={removeSectionIcon} alt="removeSectionIcon" />
      </DeleteDialog>
    );

    const dragSectionIconUI = (
      <img src={dragIcon} alt="dragIcon" className="drag-action" />
    );

    const applyStyle = (e, cmdName) => {
      e.preventDefault();
      e.stopPropagation();
      document.execCommand(cmdName);
    };

    const applyColor = (e, color) => {
      e.preventDefault();
      const focusedElement = document.activeElement;
      document.execCommand("foreColor", false, color);
      window.getSelection().removeAllRanges();
      if (focusedElement) {
        focusedElement.focus();
      }
    };

    const imageWrapperStyle = section.image ? getColorOrShadowForTemplate(section?.id, section?.id + "fixedSalt", true) : null;

    return (
      <div
        ref={ref}
        style={{
          opacity: isDragging ? 1 : 1, // Reduce opacity when dragging
          ...(isDragging ? {} : {}),
          display: isPresenting && index > activeSection ? "none" : "flex",
        }}
        className={`Slide-Div ${section.type} ${
          isDragging ? "isDragging" : ""
        }`}
        id={section.id}
      >
        {!isPresenting && !noDrag && <>{dragSectionIconUI}</>}
        {section.image ? (
          <div className="image-wrapper" style={{
            border: `1px solid ${imageWrapperStyle?.borderColor}`,
            boxShadow: imageWrapperStyle?.boxShadow
          }}>
            <ImagePreview src={section.image} className="content-img" />
          </div>
        ) : (
          <>
            {section.type === "title" && (
              <div className={`main-title index-${index}`}>
                <ContentEditable
                  key={section.id + index + "main-title"}
                  isEditable={!isPresenting}
                  content={section.content}
                  onContentChange={(newContent) => {
                    onContentChange(index, newContent, "content");
                  }}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
              </div>
            )}
            {section.type === "content" && (
              <div
                className="content-wrapper"
                style={{
                  backgroundColor: section.bgColor
                    ? section.bgColor
                    : getColorOrShadowForTemplate(slideId, slideId + "fixedSalt"),
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onPointerDown={(e) => {
                  e.stopPropagation();
                }}
              >
                <div className="content">
                  {(section.key || !isPresenting) && (
                    <ContentEditable
                      key={section.id + index + "content-title"}
                      className="content-title"
                      isEditable={!isPresenting && !noSubtitleEdit}
                      content={section.key}
                      onContentChange={(newContent) =>
                        onContentChange(index, newContent, "key")
                      }
                      placeholder="Insert Title here..."
                      onFocus={(e) => {
                        setIsTitleFocused(true);
                        handleFocus(e);
                      }}
                      onBlur={(e) => {
                        setIsTitleFocused(false);
                        handleBlur(e);
                      }}
                    />
                  )}
                  {(section.content || !section.key || !isPresenting) && (
                    <ContentEditable
                      key={section.id + index + "content"}
                      className="content"
                      isEditable={!isPresenting}
                      content={section.content}
                      onContentChange={(newContent) =>
                        onContentChange(index, newContent, "content")
                      }
                      placeholder={`Insert Content here...`}
                      onFocus={(e) => {
                        setIsContentFocused(true);
                        handleFocus(e);
                      }}
                      onBlur={(e) => {
                        setIsContentFocused(false);
                        handleBlur(e);
                      }}
                    />
                  )}
                  {(isTitleFocused || isContentFocused) && (
                    <div className="style-buttons">
                      <div className="bottons">
                        <img
                          src={boldIcon}
                          alt="bold"
                          onMouseDown={(e) => applyStyle(e, "bold")}
                        />
                        <img
                          src={italicIcon}
                          alt="italic"
                          onMouseDown={(e) => applyStyle(e, "italic")}
                        />
                        <img
                          src={underlineIcon}
                          alt="underline"
                          onMouseDown={(e) => applyStyle(e, "underline")}
                        />
                      </div>
                      <div className="colors">
                        <img
                          src={resetColorIcon}
                          alt="resetColorIcon"
                          className="reset-color-icon"
                          onMouseDown={(e) =>
                            applyColor(e, isTitleFocused ? "#0567e4" : "black")
                          }
                        />
                        {[
                          "#F2C94C",
                          "#F2994A",
                          "#EB5757",
                          "#9B51E0",
                          "#BB6BD9",
                          "#2F80ED",
                          "#2D9CDB",
                          "#56CCF2",
                        ].map((c) => (
                          <div
                            className="circle"
                            onMouseDown={(e) => applyColor(e, c)}
                            style={{ backgroundColor: c }}
                          ></div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                {!(section.key || section.content) && (
                  <img
                    src={addImageIcon}
                    onClick={() => onAddImageClick(index)}
                    className="add-image-in-section"
                    alt="addImageIcon"
                  />
                )}
              </div>
            )}
          </>
        )}

        {!isPresenting && !noRemove && <>{deleteSectionIconUI}</>}
      </div>
    );
  }
);

const Slide = ({
  slideId,
  sections,
  isVisible,
  onContentChange,
  activeSection,
  isPresenting,
  addSectin,
  deleteSection,
  onAddImageClick,
  setSections,
  activeSlide,
}) => {
  const [draggingItem, setDraggingItem] = useState(null);

  useEffect(() => {
    const t = document.getElementsByClassName("Slide-Div");
    if (t.length > 0 && activeSection > 0 && isPresenting)
      t[t.length - 1].scrollIntoView({ behavior: "smooth" });
  }, [activeSection, isPresenting]);

  const moveSection = (fromIndex, toIndex) => {
    const from = sections[fromIndex];
    const newSections = sections.slice();
    newSections.splice(fromIndex, 1);
    newSections.splice(toIndex, 0, from);
    // Call a method to set this new sections order in the parent or wherever the state is
    setDraggingItem(from);
    setSections(newSections);
  };

  if (
    activeSlide === 0 &&
    (_.find(sections, { key: "Learning Objective:" }) ||
      _.find(sections, { key: "Learning:" }) ||
      _.find(sections, { key: "Learning Objectives:" })) &&
    (_.find(sections, { key: "Starter:" }) ||
      _.find(sections, { key: "Starter Questions:" })) &&
    _.find(sections, { key: "Extension Activity:" }) &&
    _.find(sections, { key: "Success Criteria:" }) &&
    _.find(sections, { key: "Date:" })
  ) {
    return (
      <div className={`Slide ${isPresenting ? "presenting" : "editing"}`}>
        {(0 <= activeSection || !isPresenting) && (
          <SlideSection
            key={sections[0].id}
            id={0}
            moveSection={moveSection}
            deleteSection={deleteSection}
            section={sections[0]}
            index={0}
            onContentChange={onContentChange}
            activeSection={activeSection}
            isPresenting={isPresenting}
            onAddImageClick={onAddImageClick}
            noDrag={true}
            noRemove={true}
            slideId={slideId}
          ></SlideSection>
        )}
        {(1 <= activeSection || !isPresenting) && (
          <SlideSection
            key={_.find(sections, { key: "Date:" }).id}
            id={1}
            moveSection={moveSection}
            deleteSection={deleteSection}
            section={{
              ..._.find(sections, { key: "Date:" }),
              bgColor: "rgba(175, 238, 238, 0.5)",
            }}
            index={1}
            onContentChange={onContentChange}
            activeSection={activeSection}
            isPresenting={isPresenting}
            onAddImageClick={onAddImageClick}
            noDrag={true}
            noRemove={true}
            noSubtitleEdit={true}
            slideId={slideId}
          ></SlideSection>
        )}
        {(2 <= activeSection || !isPresenting) && (
          <SlideSection
            key={
              (
                _.find(sections, { key: "Learning Objective:" }) ||
                _.find(sections, { key: "Learning:" }) ||
                _.find(sections, { key: "Learning Objectives:" })
              ).id
            }
            id={2}
            moveSection={moveSection}
            deleteSection={deleteSection}
            section={{
              ...(_.find(sections, { key: "Learning Objective:" }) ||
                _.find(sections, { key: "Learning Objectives:" })),
              bgColor: "rgba(135, 206, 250, 0.5)",
            }}
            index={2}
            onContentChange={onContentChange}
            activeSection={activeSection}
            isPresenting={isPresenting}
            onAddImageClick={onAddImageClick}
            noDrag={true}
            noRemove={true}
            noSubtitleEdit={true}
            slideId={slideId}
          ></SlideSection>
        )}
        <div className="two-section-wrapper">
          <div className="two-section">
            <div className="left-section">
              {(3 <= activeSection || !isPresenting) && (
                <SlideSection
                  key={
                    (
                      _.find(sections, { key: "Starter:" }) ||
                      _.find(sections, { key: "Starter Questions:" })
                    ).id
                  }
                  id={3}
                  moveSection={moveSection}
                  deleteSection={deleteSection}
                  section={{
                    ...(_.find(sections, { key: "Starter:" }) ||
                      _.find(sections, { key: "Starter Questions:" })),
                    bgColor: "rgba(255, 215, 0, 0.5)",
                  }}
                  index={3}
                  onContentChange={onContentChange}
                  activeSection={activeSection}
                  isPresenting={isPresenting}
                  onAddImageClick={onAddImageClick}
                  noDrag={true}
                  noRemove={true}
                  noSubtitleEdit={true}
                  slideId={slideId}
                ></SlideSection>
              )}
              {(4 <= activeSection || !isPresenting) && (
                <SlideSection
                  key={_.find(sections, { key: "Extension Activity:" }).id}
                  id={4}
                  moveSection={moveSection}
                  deleteSection={deleteSection}
                  section={{
                    ..._.find(sections, { key: "Extension Activity:" }),
                    bgColor: "rgba(255, 255, 153, 0.5)",
                  }}
                  index={4}
                  onContentChange={onContentChange}
                  activeSection={activeSection}
                  isPresenting={isPresenting}
                  onAddImageClick={onAddImageClick}
                  noDrag={true}
                  noRemove={true}
                  noSubtitleEdit={true}
                  slideId={slideId}
                ></SlideSection>
              )}
            </div>
            {5 <= activeSection || !isPresenting ? (
              <SlideSection
                key={_.find(sections, { key: "Success Criteria:" }).id}
                id={5}
                moveSection={moveSection}
                deleteSection={deleteSection}
                section={{
                  ..._.find(sections, { key: "Success Criteria:" }),
                  bgColor: "rgba(152, 251, 152, 0.5)",
                }}
                index={5}
                onContentChange={onContentChange}
                activeSection={activeSection}
                isPresenting={isPresenting}
                onAddImageClick={onAddImageClick}
                noDrag={true}
                noRemove={true}
                noSubtitleEdit={true}
                slideId={slideId}
              ></SlideSection>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    );
  }
  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className={`Slide ${isPresenting ? "presenting" : "editing"}`}
        style={{
          display: isVisible ? "block" : "none",
          position: "relative",
        }}
      >
        <FlipMove
          typeName={null} // Use a fragment, not a div
          enterAnimation="accordionVertical"
          leaveAnimation="accordionVertical"
          duration={500}
        >
          {sections.map((section, index) => {
            return (
              <>
                {(index <= activeSection || !isPresenting) && (
                  <DraggableSlideSection
                    key={section.id}
                    id={index}
                    moveSection={moveSection}
                    // <SlideSection
                    deleteSection={deleteSection}
                    section={section}
                    index={index}
                    // key={section.id + index}
                    onContentChange={onContentChange}
                    activeSection={activeSection}
                    isPresenting={isPresenting}
                    onAddImageClick={onAddImageClick}
                    draggingItem={draggingItem}
                    setDraggingItem={setDraggingItem}
                    slideId={slideId}
                  />
                )}
              </>
            );
          })}
        </FlipMove>
        {!isPresenting && (
          <div
            className="add-new-section-btn blue-btn max-191"
            onClick={() => {
              addSectin(sections.length - 1, "text", false, "", "");
            }}
          >
            <img src={whitePlusIcon} alt="whitePlusIcon" />
            <span>Add New Section</span>
          </div>
        )}
      </div>
    </DndProvider>
  );
};

export default Slide;
