const colorList = [
"#C4DFDF",
"#D2E9E9",
"#E3F4F4",
"#F8F6F4",
"#CCD5AE",
"#E9EDC9",
"#FEFAE0",
"#FAEDCD",
];

const borderShadowPairs = [
  { borderColor: "#FF5733", boxShadow: "8px 8px 15px rgba(255, 87, 51, 0.5)" },
  { borderColor: "#33FF57", boxShadow: "8px 8px 15px rgba(51, 255, 87, 0.5)" },
  { borderColor: "#3357FF", boxShadow: "8px 8px 15px rgba(51, 87, 255, 0.5)" },
  { borderColor: "#FF33A1", boxShadow: "8px 8px 15px rgba(255, 51, 161, 0.5)" },
  { borderColor: "#FFA533", boxShadow: "8px 8px 15px rgba(255, 165, 51, 0.5)" },
  { borderColor: "#33FFA5", boxShadow: "8px 8px 15px rgba(51, 255, 165, 0.5)" },
  { borderColor: "#A533FF", boxShadow: "8px 8px 15px rgba(165, 51, 255, 0.5)" },
  { borderColor: "#5733FF", boxShadow: "8px 8px 15px rgba(87, 51, 255, 0.5)" },
  { borderColor: "#FF3357", boxShadow: "8px 8px 15px rgba(255, 51, 87, 0.5)" },
  { borderColor: "#33D1FF", boxShadow: "8px 8px 15px rgba(51, 209, 255, 0.5)" },
  { borderColor: "#FFD133", boxShadow: "8px 8px 15px rgba(255, 209, 51, 0.5)" },
  { borderColor: "#33FFD1", boxShadow: "8px 8px 15px rgba(51, 255, 209, 0.5)" },
  { borderColor: "#D133FF", boxShadow: "8px 8px 15px rgba(209, 51, 255, 0.5)" },
  { borderColor: "#33FF9E", boxShadow: "8px 8px 15px rgba(51, 255, 158, 0.5)" },
  { borderColor: "#FF9E33", boxShadow: "8px 8px 15px rgba(255, 158, 51, 0.5)" },
  { borderColor: "#339EFF", boxShadow: "8px 8px 15px rgba(51, 158, 255, 0.5)" },
  { borderColor: "#9EFF33", boxShadow: "8px 8px 15px rgba(158, 255, 51, 0.5)" },
  { borderColor: "#FF33D1", boxShadow: "8px 8px 15px rgba(255, 51, 209, 0.5)" },
  { borderColor: "#D1FF33", boxShadow: "8px 8px 15px rgba(209, 255, 51, 0.5)" },
  { borderColor: "#FF5733", boxShadow: "8px 8px 15px rgba(255, 87, 51, 0.5)" },
];

function hashTemplateId(templateId, salt = "") {
  let hash = 0;
  const combinedId = templateId + salt;
  for (let i = 0; i < combinedId.length; i++) {
    const char = combinedId.charCodeAt(i);
    hash = (hash * 31 + char) % 1000000;
  }
  return hash;
}

function seededRandom(seed) {
  let x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
}

export function getColorOrShadowForTemplate(
  templateId,
  salt = "",
  isNeedBorderShadowPair = false
) {
  const pairArray = isNeedBorderShadowPair ? borderShadowPairs : colorList;
  const seed = hashTemplateId(templateId.toString(), salt);
  const randomValue = seededRandom(seed);
  const Index = Math.floor(randomValue * pairArray.length);
  return pairArray[Index];
}
